import * as TYPES from './type'

export default {
  [TYPES.SET_LANG]: (state, payload) => {
    state.lang = payload
  },
  [TYPES.SET_PROFILE]: (state, payload) => {
    state.profile = payload
  },
  [TYPES.SET_COUNTRY]: (state, payload) => {
    state.country = payload
  },

  /* 购物车相关 */
  [TYPES.ADD_TO_CART]: (state,payload) => {
    const item = state.cart.find(item => item.id === payload.id && item.configId === payload.configId);
    if (!item) {
        state.cart.push({ ...payload });
    } else {
        item.quantity += payload.quantity;  
    }
  },
  [TYPES.SET_QUANTITY]: (state, payload) => {
    const item = state.cart.find(item => item.id === payload.id && item.configId === payload.configId);
    item.quantity=payload.quantity;
  },
  [TYPES.REMOVE_FROM_CART]: (state, payload) => {
    state.cart = state.cart.filter(item => item.id !== payload.id || item.configId !==payload. configId);
  },
  [TYPES.CLEAR_CART]: (state) => {
    state.cart = [];
  },
  [TYPES.SET_CART]: (state, payload) => {
    state.cart = payload;
  }

}
