export default {
  lang: '',
  country:{},
  profile: {},
  //加载购物车
  cart: (() => {
    //这里判断localStorage中是否有cart，且是否能解析为JSON，如果不能则返回空数组，防止用户在localStorage中存储了非法数据导致程序崩溃
    try {
      return localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];
    } catch (error) {
      console.error("Error parsing 'cart' from localStorage:", error);
      return []; 
    }
  })()
}
