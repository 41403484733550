<template>
  <div>
    <div class="wrap" v-if="platform.isPC">
      <div class="background">
        <img
          src="https://ai.modehoist.com/gowow/website/Bottom_Background.png"
          alt=""
        />
      </div>
      <div class="subject">
        <div class="head" v-if="false">
          <img class="logo" src="@assets/imgs/logo.png" alt="gowow" />
          <span class="icon_add">+</span>
        </div>
        <div class="list">
          <div class="item">
            <div class="title">{{ $t("index.产品") }}</div>
            <div class="content">
              <div class="content-item clickable" @click="goto('/product/ORI')">
                ORI
              </div>
              <div class="content-item clickable" @click="goto('/product/Alpha')">
                ALPHA
              </div>
            </div>
            
          </div>
          <div class="item">
            <div class="title">Gowow</div>
            <div class="content">
              
              <div class="content-item clickable" @click="goto('/brand/concept')">
                {{ $t("index.品牌理念") }}
              </div>
              
              <div class="content-item" v-if="false">
                {{ $t("index.概念产品预告") }}
              </div>
              
              <div v-if="!isCN" class="content-item clickable" @click="goto('/about')">
                {{ $t("index.我们的故事") }}
              </div>
             
              <div class="content-item clickable" @click="goto('/brand/news')">
                {{ $t("index.品牌大事件") }}
              </div>



              <div v-if="isCN" class="content-item clickable" @click="goto('/brand/class')">
                {{ $t("index.品牌课堂") }}
              </div>
              
              <div v-if="!isCN" class="content-item clickable" @click="goto('/contact')">
                {{ $t("index.联系我们") }}
              </div>
      
            </div>
          </div>
          <div class="item" v-if="false">
            <div class="title">{{ $t("index.使用说明") }}</div>
            <div class="content">
              <div class="content-item">{{ $t("index.产品完整说明书") }}</div>
              <div class="content-item">{{ $t("index.使用手册") }}</div>
              <div class="content-item">FAQ</div>
            </div>
          </div>
          <div class="item" v-if="!isCN">
            <div class="title">{{ $t("index.法律条款") }}</div>
            <div class="content">
              <div class="content-item clickable" @click="goto('/terms')">
                Terms of service
              </div>
              <div class="content-item clickable" @click="goto('/privacy')">
                Privacy policy
              </div>
              <div class="content-item clickable" @click="goto('/shipping')">
                Shipping policy
              </div>
              <div class="content-item clickable" @click="goto('/return')">
                Return & Refund policy
              </div>
              <div class="content-item clickable" @click="goto('/imprint')">
                Imprint
              </div>
            </div>
          </div>
          <div v-if="!isCN" class="item" >
            <div class="title">{{ $t("index.帮助") }}</div>
            <div class="content">
              <div class="content-item clickable" @click="goto('/partner') ">
                {{ $t("index.合作伙伴申请") }}
              </div>

              <div class="content-item clickable" @click="goto('/trial')">
                {{ $t("index.预约试驾") }}
              </div>

              <div class="content-item clickable" @click="goto('/troubleshooting')" v-if="false">
                {{ $t("index.故障排除") }}
              </div>
              
              <div class="content-item clickable" @click="goto('/faq')" >
                {{ $t("index.常见问题") }}
              </div>

              <div class="content-item clickable" @click="goto('/press')">
                {{ $t("index.媒体中心") }}
              </div>
            </div>
          </div>
          <div class="item" v-if="isCN">
            <div class="title">
              {{ $t("index.社区") }}
            </div>
            <div class="content">
              <div
                class="content-item clickable"
                @click="goto('/motocircle/list')"
              >
                {{ $t("lifeStyle.摩友圈") }}
              </div>
            </div>
          </div>
          <div class="item" v-if="isCN">
            <div class="title">{{ $t("index.联系我们") }}</div>
            <div class="content">
              <div class="content-item" v-if="isCN">
                {{ $t("index.联系我们") }}：010-63724080
              </div>
              <div class="content-item" v-if="!isCN">
                {{ $t("index.联系我们") }}：+86-10-63724080
              </div>
              <div class="content-item">
                {{ $t("index.发送邮件") }}：service@gowow.com
              </div>
            </div>
          </div>
          <div class="item">
            <div class="title">{{ $t("index.关注我们") }}</div>
            <div class="content" v-if="isCN">
              <div class="content-item">
                {{ $t("index.微博") }}：@Gowow格物官方微博
              </div>
              <div class="content-item">{{ $t("index.微信") }}：@格物Gowow</div>
            </div>
            <div class="Eicon" v-if="!isCN">
              <a href=" https://discord.gg/Y2eeRpXE6Y" target="_blank"
                ><img src="@assets/imgs/footer-icon/discord.png"
              /></a>
              <a href="https://www.instagram.com/gowowofficial/" target="_blank"
                ><img src="@assets/imgs/footer-icon/ins.png"
              /></a>
              <a
                href="https://www.youtube.com/@gowow-official"
                target="_blank"
                ><img src="@assets/imgs/footer-icon/youtube.png"
              /></a>
              <a href="https://www.tiktok.com/@gowowofficial" target="_blank"
                ><img src="@assets/imgs/footer-icon/tiktok.png"
              /></a>
            </div>
          </div>
          <div class="item">
            <div class="title">{{ $t("index.服务与支持") }}</div>
            <div class="content">
              <div class="content-item clickable" @click="goto('/download')">
                {{ $t("index.下载") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright">
        <p v-if="isCN">
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >京ICP备2023020355号-1</a
          >
        </p>
        &nbsp;|&nbsp;
        <div>Copyright © 2022 Gowow</div>
        &nbsp;|&nbsp;
        <div>All rights reserved. Terms &amp; conditions</div>
      </div>
    </div>
    <div class="mwrap" v-else>
      <div class="esc" @click="esctop">
        <span>{{ $t("index.回到顶部") }}</span
        ><i class="el-icon-arrow-up"></i>
      </div>
      <div :class="['nav-item', { active: spreadNavIdx === 0 }]">
        <div class="nav-item-content" @click="setSpreadNavIdx(0)">
          <span>{{ $t("index.产品") }}</span>
          <i></i>
        </div>
        <div class="sub-nav">
          <div
            :class="['sub-nav-item', { active: curNav === '0_1' }]"
            @click="onNavClick(0, 1)"
          >
            ORI
          </div>
          <div
            :class="['sub-nav-item', { active: curNav === '0_2' }]"
            @click="onNavClick(0, 2)"
          >
            ALPHA
          </div>
        </div>
      </div>
      <div :class="['nav-item', { active: spreadNavIdx === 3 }]">
        <div class="nav-item-content" @click="setSpreadNavIdx(3)">
          <span>Gowow</span>
          <i></i>
        </div>
        <div class="sub-nav">
          <div
            :class="['sub-nav-item', { active: curNav === '3_1' }]"
            @click="onNavClick(3, 1)"
          >
            {{ $t("index.品牌理念") }}
          </div>
          <div
            v-if="!isCN"
            :class="['sub-nav-item', { active: curNav === '8_6' }]"
            @click="onNavClick(8,6)"
          >
            {{ $t("index.我们的故事") }}
          </div>
          <div
            :class="['sub-nav-item', { active: curNav === '7_0' }]"
            @click="onNavClick(7)"
          >
            {{ $t("index.品牌大事件") }}
          </div>
          <div
            v-if="isCN"
            :class="['sub-nav-item', { active: curNav === '2_2' }]"
            @click="onNavClick(2, 2)"
          >
            {{ $t("index.品牌课堂") }}
          </div>
          <div
            v-if="!isCN"
            :class="['sub-nav-item', { active: curNav === '8_5' }]"
            @click="onNavClick(8, 5)"
          >
            Contact us
          </div>
        </div>
      </div>
      
      
      <div v-if="!isCN" :class="['nav-item', { active: spreadNavIdx === 8 }]">
        <div class="nav-item-content" @click="setSpreadNavIdx(8)">
          <span>{{ $t("index.法律条款") }}</span>
          <i></i>
        </div>
        <div class="sub-nav">
          <div
            :class="['sub-nav-item', { active: curNav === '8_1' }]"
            @click="onNavClick(8, 1)"
          >
            Terms of service
          </div>
          <div
            :class="['sub-nav-item', { active: curNav === '8_2' }]"
            @click="onNavClick(8, 2)"
          >
            Privacy policy
          </div>
          <div
            :class="['sub-nav-item', { active: curNav === '8_3' }]"
            @click="onNavClick(8, 3)"
          >
            Shipping policy
          </div>
          <div
            :class="['sub-nav-item', { active: curNav === '8_4' }]"
            @click="onNavClick(8, 4)"
          >
            Return & Refund policy
          </div>
          <div
            :class="['sub-nav-item', { active: curNav === '8_7' }]"
            @click="onNavClick(8, 7)"
          >
            Imprint
          </div>
        </div>
      </div>
      <div v-if="!isCN" :class="['nav-item', { active: spreadNavIdx === 9 }]">
        <div class="nav-item-content" @click="setSpreadNavIdx(9)">
          <span>{{ $t("index.帮助") }}</span>
          <i></i>
        </div>
        <div class="sub-nav">
          <div
            :class="['sub-nav-item', { active: curNav === '9_1' }]"
            @click="onNavClick(9, 1)"
          >
            {{ $t("index.合作伙伴申请") }}
          </div>
          <div
            :class="['sub-nav-item', { active: curNav === '9_2' }]"
            @click="onNavClick(9,2)"
          >
            {{ $t("index.预约试驾") }}
          </div>
          <div
            v-if="false"
            :class="['sub-nav-item', { active: curNav === '9_3' }]"
            @click="onNavClick(9,3)"
          >
            {{ $t("index.故障排除") }}
          </div>
          <div
            :class="['sub-nav-item', { active: curNav === '2_4' }]"
            @click="onNavClick(2, 4)"
          >
            {{ $t("index.常见问题") }}
          </div>
          <div
            :class="['sub-nav-item', { active: curNav === '10_1' }]"
            @click="onNavClick(10, 1)"
          >
            {{ $t("index.媒体中心") }}
          </div>
        </div>
      </div>
      <div :class="['nav-item']" v-if="false">
        <div class="nav-item-content" @click="onNavClick(1)">
          <span> {{ $t("index.vr看车") }}</span>
        </div>
      </div>
      <div :class="['nav-item']" v-if="false">
        <div class="nav-item-content" @click="gopartner">
          <span> {{ $t("partner.合作伙伴") }}</span>
        </div>
      </div>
      <div :class="['nav-item', { active: spreadNavIdx === 7 }]" v-if="false">
        <div class="nav-item-content" @click="setSpreadNavIdx(7)">
          <span>{{ $t("index.关于我们") }}</span>
          <i></i>
        </div>
        <div class="sub-nav">
          <div
            :class="['sub-nav-item', { active: curNav === '7_1' }]"
            @click="onNavClick(7, 1)"
          >
            {{ $t("lifeStyle.格物电动摩托车") }}
          </div>
        </div>
      </div>
      <div :class="['nav-item', { active: spreadNavIdx === 6 }]" v-if="isCN">
        <div class="nav-item-content" @click="setSpreadNavIdx(6)">
          <span>{{ $t("index.社区") }}</span>
          <i></i>
        </div>
        <div class="sub-nav">
          <div
            :class="['sub-nav-item', { active: curNav === '6_1' }]"
            @click="onNavClick(6, 1)"
          >
          {{ $t("lifeStyle.摩友圈") }}
          </div>
        </div>
      </div>
      <div :class="['nav-item', { active: spreadNavIdx === 2 }]">
        <div class="nav-item-content" @click="setSpreadNavIdx(2)">
          <span>{{ $t("index.服务与支持") }}</span>
          <i></i>
        </div>
        <div class="sub-nav">
          <div
            :class="['sub-nav-item', { active: curNav === '2_1' }]"
            @click="onNavClick(2, 1)"
          >
            {{ $t("index.下载") }}
          </div>
        </div>
      </div>
      <div v-if="!isCN" class="popover">
        <div class="Eicon" v-if="!isCN">
          <a href=" https://discord.gg/Y2eeRpXE6Y" target="_blank"
            ><img src="@assets/imgs/footerM-icon/discord.png"
          /></a>
          <a href="https://www.instagram.com/gowowofficial/" target="_blank"
            ><img src="@assets/imgs/footerM-icon/ins.png"
          /></a>
          <a href="https://www.youtube.com/@gowow-official" target="_blank"
            ><img src="@assets/imgs/footerM-icon/youtube.png"
          /></a>
          <a href="https://www.tiktok.com/@gowowofficial" target="_blank"
            ><img src="@assets/imgs/footerM-icon/tiktok.png"
          /></a>
        </div>
      </div>
      <div class="popover" v-if="isCN">
        <!-- <div v-if="!isCN">
          <el-popover
            placement="top"
            width="auto"
            v-model="visible1"
            trigger="manual"
          >
            <p style="font-size: 140%; line-height: 200%">@Gowow Dirt Ebike</p>
            <div style="text-align: center; margin: 0"></div>
            <img
              src="@assets/imgs/footerM-icon/facebook-fill.png"
              @click="visible1 = !visible1"
              slot="reference"
            />
          </el-popover>
        </div>
        <div v-if="!isCN">
          <el-popover
            placement="top"
            width="auto"
            v-model="visible2"
            trigger="manual"
          >
            <p style="font-size: 140%; line-height: 200%">@GOWOWMOTO</p>
            <div style="text-align: center; margin: 0"></div>
            <img
              src="@assets/imgs/footerM-icon/instagram-fill.png"
              @click="visible2 = !visible2"
              slot="reference"
            />
          </el-popover>
        </div> -->
        <div>
          <el-popover
            placement="top"
            width="auto"
            v-model="visible5"
            trigger="manual"
          >
            <p style="font-size: 140%; line-height: 200%">@格物Gowow</p>
            <div style="text-align: center; margin: 0"></div>
            <img
              src="@assets/imgs/footerM-icon/wechat-fill.png"
              @click="visible5 = !visible5"
              slot="reference"
            />
          </el-popover>
        </div>
        <div>
          <el-popover
            placement="top"
            width="auto"
            v-model="visible6"
            trigger="manual"
          >
            <p style="font-size: 140%; line-height: 200%">@Gowow格物官方微博</p>
            <div style="text-align: center; margin: 0"></div>
            <img
              src="@assets/imgs/footerM-icon/weibo-circle-fill.png"
              @click="visible6 = !visible6"
              slot="reference"
            />
          </el-popover>
        </div>
        <div>
          <el-popover
            placement="top"
            width="auto"
            v-model="visible3"
            trigger="manual"
          >
            <p style="font-size: 140%; line-height: 200%">service@gowow.com</p>
            <div style="text-align: center; margin: 0"></div>
            <img
              src="@assets/imgs/footerM-icon/mail-fill.png"
              @click="visible3 = !visible3"
              slot="reference"
            />
          </el-popover>
        </div>
        <div>
          <el-popover
            placement="top"
            width="auto"
            v-model="visible4"
            trigger="manual"
          >
            <p style="font-size: 140%; line-height: 200%">+86-10-63724080</p>
            <div style="text-align: center; margin: 0"></div>
            <img
              src="@assets/imgs/footerM-icon/phone-fill.png"
              @click="visible4 = !visible4"
              slot="reference"
            />
          </el-popover>
        </div>
      </div>
      <div class="copyrightm">
        <p v-if="isCN">
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >京ICP备2023020355号-1</a
          >
        </p>
        <p>Copyright © 2022 Gowow</p>
        <p>All rights reserved. Terms &amp; conditions</p>
      </div>
    </div>
  </div>
</template>

<script>
import { goto } from "@utils/goto";
import { mapState } from "vuex";
import platform from "@utils/platform";
import RouteMap, { activeRoute } from "./Header/RouteMap";

export default {
  computed: {
    ...mapState(["lang"]),
    isCN() {
      return this.lang === "zh-CN";
    },
  },
  data() {
    return {
      platform,
      curNav: activeRoute,
      spreadNavIdx: 12,
      visible1: false,
      visible2: false,
      visible3: false,
      visible4: false,
      visible5: false,
      visible6: false,
    };
  },
  methods: {
    goto,
    setSpreadNavIdx(idx) {
      //  2暂时没有菜单
      // if ([2].includes(idx)) return;
      if (idx === this.spreadNavIdx) {
        this.spreadNavIdx = "";
      } else {
        this.spreadNavIdx = idx;
      }
    },
    onNavClick(navIdx, nav2Idx = 0) {
      this.curNav = `${navIdx}_${nav2Idx}`;
      const url = RouteMap[this.curNav];
      if (url) {
        goto(url);
      }
    },
    gopartner() {
      goto("/partner");
    },
    esctop() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style lang='scss' scoped>
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/mixin.scss";
@import "~@assets/styles/func.scss";

.clickable {
  cursor: pointer;
}

.mwrap {
  font-size: 0;
  width: 100vw;
  height: auto;
  padding: 0 px2rem(20px) px2rem(20px) px2rem(20px);
  background-color: black;
  color: white;
  position: relative;
  z-index: 20;
  .esc {
    width: 100%;
    text-align: center;
    font-size: px2rem(16px);
    font-family: PingFang SC;
    font-weight: 400;
    line-height: px2rem(40px);
    border-bottom: px2rem(1px) solid #9f9f9f;
  }

  .copyrightm {
    padding-top: px2rem(10px);
    font-family: HelveticaNeueLTPro-Roman;
    font-size: px2rem(12px);
    line-height: px2rem(18px);
    text-transform: uppercase;
    border: none;
    a {
      color: white;
    }
  }
  .popover {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin: px2rem(20px) 0 px2rem(5px);
    div {
      width: 15%;

      img {
        width: 100%;
      }
    }
    .Eicon {
      width: 100%;
      display: flex;
      justify-content: space-around;
      img {
        width: px2rem(50px);
      }
    }
  }
  .nav-item {
    // margin-top: px2rem(5px);
    // line-height: px2rem(70px);
    width: 100%;
    // 展开
    &.active {
      height: auto;
      .nav-item-content i {
        background-image: url(@assets/imgs/footerM-icon/top.png);
      }
      .sub-nav {
        display: block;
        border: none;
      }
    }
    .nav-item-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: px2rem(17px);
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      line-height: px2rem(40px);
      height: px2rem(50px);
      // padding-bottom: px2rem(3px);
      border-bottom: px2rem(1px) solid #9f9f9f;
      i {
        width: px2rem(40px);
        height: px2rem(40px);
        background: url(@assets/imgs/footerM-icon/right.png) no-repeat center;
        background-size: 60%;
      }
    }
    .sub-nav {
      display: none;
      // padding-bottom: px2rem(20px);
      font-size: px2rem(14px);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      background-color: #333;
      // line-height: px2rem(20px);
      .sub-nav-item {
        font-size: px2rem(16px);
        font-family: PingFang-SC-Bold, PingFang-SC;
        line-height: px2rem(30px);
        padding: px2rem(8px) px2rem(5px);
        border-bottom: #fff 1px dotted;
        // height: px2rem(50px);
        &.active {
          color: #e8e354;
        }
        &:nth-child(n + 2) {
          font-weight: 400;
        }
      }
    }
  }
}

.wrap {
  // box-shadow: 0px 0px 12px 0px rgba(215, 215, 215, 0.5);
  // display: flex;
  // justify-content: space-between;
  position: relative;
  z-index: 2;
  img {
    vertical-align: top;
  }

  .head {
    display: none;
  }
  .background {
    height: 260px;
    vertical-align: top;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .subject {
    width: 100%;
    position: absolute;
    top: 0;
  }
  .list {
    width: 100%;
    margin-top: 50px;
    margin-left: 50px;
    flex: 1;
    display: flex;
    // justify-content: space-around;

    .item {
      white-space: nowrap;
      // width: 180px;
      // margin: 0 50px;
      
      
        margin: 0 50px;
      

      .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        
        // font-family: PingFangSC-Regular;
        color: #fff;
      }

      .content {
        margin-top: 4px;
        // font-family: PingFangSC-Thin;
        font-size: 12px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.8);
        .content-item {
          margin-top: 5px;
          
          &:hover {
            color: #e8e354;
          }
        }
      }
      .Eicon {
        margin-top: 4px;
        font-size: 10px;
        a {
          margin-right: 15px;
        }
        // display: flex;
        // flex-direction: row;
        img {
          width: 30px;
          // transform: scale(0.2);
          cursor: pointer;
        }
      }
    }
  }
  .copyright {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 35px;
    // height: 44px;
    font-family: HelveticaNeueLTPro-Roman;
    font-size: 12px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.6);
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    a {
      color: rgba(255, 255, 255, 0.6);
      &:hover {
        color: orange;
      }
    }
  }
}
</style>
